import { css } from "styled-components";

const sizes = {
    SMALLPHONES: 320,
    PHONES: 480,
    TABLETS: 768,
    SMALLDESKTOPS: 992,
    DESKTOPS: 1281,
};

export const BREAKPOINT = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media only screen and (min-width: ${sizes[label] / 16}em) {
            ${css(...args)}
        }
    `;

    return acc;
}, {});

export const FONT = {
    FAMILY: {
        HEADINGS: "signo, sans-serif",
        TEXT: "ibm-plex-mono, sans-serif",
        LOGO: "pragmatica-extended, sans-serif",
    },
};

export const SPACING = {
    S: 4,
    M: 8,
    L: 16,
    XL: 24,
    XXL: 48,
    XXXL: 64,
};

export const COLOR = {
    PRIMARY: "#ffc61a",
};
