import React from "react";
import ReactGA from "react-ga";
import parse from "html-react-parser";

import GlobalStyle from "./global/styles";
import styled from "styled-components";
import { SPACING, FONT, BREAKPOINT } from "./global/config.styles";
import { ICON } from "./global/icons";

const YugoWrapper = styled.div`
    position: relative;
    display: flex;
    min-height: 100vh;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 ${SPACING.L}px;
`;

const YugoIntro = styled.div`
    padding: ${SPACING.XXXL * 2}px 0 ${SPACING.XXXL}px 0;
    ${BREAKPOINT.TABLETS`padding: ${SPACING.XXXL * 2}px 0;`};
`;

const YugoInfo = styled.div``;

const InfoDesktop = styled.div`
    display: none;
    justify-content: space-between;
    padding-bottom: ${SPACING.XXXL}px;
    ${BREAKPOINT.TABLETS`display: flex;`};

    div {
        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
        border: solid 3px #fff;
        width: 48%;
        text-align: center;
        transition: all 0.2s;

        a {
            display: block;
            height: 100%;
            width: 100%;
            padding: ${SPACING.XXL}px;
            color: #fff;
            text-decoration: none;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
            background: #fff;

            a {
                color: #101010;
            }
        }
    }

    strong {
        font-family: ${FONT.FAMILY.HEADINGS};
        font-weight: 900;
        font-size: 26px;
    }
`;

const InfoMobile = styled.div`
    border-style: solid;
    border-image-slice: 1;
    border-width: 1px 0 0 0;
    border-image-source: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.25),
        rgba(255, 255, 255, 0)
    );
    padding: ${SPACING.XXXL}px 0 ${SPACING.XXXL * 2}px 0;

    ${BREAKPOINT.TABLETS`display: none;`}
`;

const InfoItem = styled.div`
    padding-bottom: ${SPACING.XL}px;
    a {
        color: #fff;
    }
    h3 {
        font-family: ${FONT.FAMILY.HEADINGS};
        font-size: 20px;
    }
`;
const IntroLogo = styled.div`
    position: absolute;
    top: ${SPACING.L}px;
    font-family: ${FONT.FAMILY.HEADINGS};
    letter-spacing: 3px;
    color: #fff;

    a {
        color: inherit;
        text-decoration: none;
    }
`;

const IntroInsta = styled.div`
    position: absolute;
    top: ${SPACING.L}px;
    right: ${SPACING.L}px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);

    svg {
        fill: rgba(255, 255, 255, 0.5);
        position: absolute;
        right: 75px;
        top: -3px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;

const IntroClaim = styled.h1`
    font-size: 64px;
    font-family: ${FONT.FAMILY.HEADINGS};
    line-height: 0.9;
    letter-spacing: -3px;
    padding-bottom: 20px;
    ${BREAKPOINT.TABLETS`font-size: 110px;`}
    margin-bottom: ${SPACING.XL}px;
    background: -webkit-linear-gradient(90deg, #cd8837, #f10000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const IntroDesc = styled.p`
    font-size: 19px;
    line-height: 1.4;
    ${BREAKPOINT.TABLETS`font-size: 24px;`}

    a {
        color: #fff;
    }
`;

const YugoCTA = styled.div`
    position: fixed;
    font-family: ${FONT.FAMILY.HEADINGS};
    right: ${SPACING.L}px;
    bottom: ${SPACING.XL}px;
    height: 80px;
    width: calc(100% - ${SPACING.L}px - ${SPACING.L}px);
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    border: solid 3px #000;
    background-color: #fff;
    /*box-shadow: 0 1px 5px rgba(0, 0, 0, 0.8), 0 0 60px rgba(242, 61, 0, 0.45);*/
    display: flex;
    ${BREAKPOINT.TABLETS`display: none;`}

    a {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: #101010;
        text-decoration: none;
        font-size: 22px;
        font-weight: 900;
        letter-spacing: -1px;
    }

    svg {
        width: 48px;
        height: 48px;
    }
`;

const YugoFooter = styled.div`
    display: none;
    ${BREAKPOINT.TABLETS`display: flex;`}
    bottom: ${SPACING.L}px;
    color: rgba(255, 255, 255, 0.3);
    justify-content: space-between;
    padding-bottom: ${SPACING.XXL}px;

    div {
        width: 150px;   

        &:first-of-type {
            width: 300px;
        }
    }

    span {
        font-size: 13px;
    }
    h3 {
        font-family: ${FONT.FAMILY.HEADINGS};
        font-weight: 900;
        font-size: 16px;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        list-style-type: none;
    }
`;

function App() {
    ReactGA.initialize("UA-170822595-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <React.Fragment>
            <GlobalStyle />
            <YugoWrapper>
                <IntroLogo>
                    <a href="/">YUGO</a>
                </IntroLogo>
                <IntroInsta>
                    <a
                        href="https://instagram.com/eatyugo/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {parse(ICON.INSTAGRAM)}
                        @eatyugo
                    </a>
                </IntroInsta>
                <YugoIntro>
                    <IntroClaim>Sushi & Poke to go</IntroClaim>
                    <IntroDesc>
                        Ven y prueba el nuevo establecimiento de sushi y poke
                        para llevar y a domicilio en Begues. Estamos en la{" "}
                        <a href="https://goo.gl/maps/tcCULp4esLuZEoLz7">
                            Plaça de l'Ateneu 5, Local 2
                        </a>
                    </IntroDesc>
                </YugoIntro>
                <YugoInfo>
                    <InfoDesktop>
                        <div>
                            <a
                                href="/carta/YUGO - Sushi and Poke.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Mira <br />
                                <strong>nuestra carta</strong>
                            </a>
                        </div>
                        <div>
                            <a href="tel: +34 93 144 85 66">
                                Tu pedido <br />
                                <strong>93 144 85 66</strong>
                            </a>
                        </div>
                    </InfoDesktop>
                    <InfoMobile>
                        <InfoItem>
                            <span>Mira</span>
                            <h3>
                                <a
                                    href="/carta/YUGO - Sushi and Poke.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Nuestra Carta
                                </a>
                            </h3>
                        </InfoItem>
                        <InfoItem>
                            <span>Martes a Domingo</span>
                            <h3>13h a 15:30h — 20h a 22:30h</h3>
                        </InfoItem>
                        <InfoItem>
                            <span>Servicio a domicilio</span>
                            <h3>1,5€</h3>
                        </InfoItem>
                        <InfoItem>
                            <span>Telf.</span>
                            <h3>
                                <a href="tel: +34 93 144 85 66">93 144 85 66</a>
                            </h3>
                        </InfoItem>
                    </InfoMobile>
                </YugoInfo>
                <YugoFooter>
                    <div>
                        <ul>
                            <li>
                                <span>Lunes</span>
                                <h3>19:30h a 22h</h3>
                            </li>
                            <li>
                                <span>Martes a Jueves</span>
                                <h3>13h a 15h — 19:30h a 22h</h3>
                            </li>
                            <li>
                                <span>Viernes</span>
                                <h3>13h a 15h — 19:30h a 22:30h</h3>
                            </li>
                            <li>
                                <span>Sábado</span>
                                <h3>19:30h a 22:30h</h3>
                            </li>
                            <li>
                                <span>Domingo</span>
                                <h3>19:30h a 22h</h3>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span>A domicilio por</span>
                        <h3>1,15€</h3>
                    </div>
                    <div>
                        <span>Telf.</span>
                        <h3>
                            <a href="tel: +34 93 144 85 66">93 144 85 66</a>
                        </h3>
                    </div>
                </YugoFooter>
            </YugoWrapper>
            <YugoCTA>
                <a href="tel: +34 93 144 85 66">
                    {parse(ICON.PHONE)} Haz tu pedido
                </a>
            </YugoCTA>
        </React.Fragment>
    );
}

export default App;
