import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { FONT, BREAKPOINT } from "./config.styles";

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    color: #fff;
    font-family: ${FONT.FAMILY.TEXT};
    font-weight: 500;
    background: #101010;
    ${BREAKPOINT.TABLETS`background: #101010 url(/bg.png) no-repeat 140% 0;background-size: 80%;`};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;  
  }
`;

export default GlobalStyle;
